











































import { Component, Prop, Vue } from "vue-property-decorator";
import {
	RedCapital,
	SelectedCountry,
	TitleTemplatePublic
} from "@/configuration";
import CollapseQuestions from "@/components/sitio/CollapseQuestions.vue";
import ShowLoading from "@/components/panel/ShowLoading.vue";
import { Normalizer } from "@/utils";

@Component({
	metaInfo: {
		title: "Garantias y riesgos",
		titleTemplate: TitleTemplatePublic,
		htmlAttrs: {
		// @ts-ignore
		lang: RedCapital[SelectedCountry].Lang
		}
	},
	data() {
		return {
		radio: 'inversionista',
		search: "",
		warranty: [
			{
			id: "1",
			question: "¿Qué es RedCapital?",
			answers:
				"Es una plataforma web tipo marketplace donde usuarios pueden elegir entre distintas opciones de inversión para obtener atractivas rentabilidades con riesgo acotado. Las opciones de inversión disponibles son operaciones de factoring."
			},
			{
			id: "2",
			question: "¿En que consiste el factoring?",
			answers:
				"A través del factoring, las empresas proveedoras ceden a los inversionistas cuentas por cobrar representadas en Facturas Negociables y reciben a cambio un adelanto de dinero. En la fecha pactada, la empresa obligada (cliente del proveedor que recibió el adelanto) cancela al inversionista el monto detallado en la factura. Las Facturas Negociables son títulos valores que, al contar con la conformidad de la empresa obligada, cuentan con mérito ejecutivo. En caso de retraso o incumplimiento en el pago, las facturas pueden ser protestadas y ejecutadas. RedCapital realiza las gestiones de cobranza. En caso la empresa obligada no cancele la factura, RedCapital cobrará a la empresa que recibió el adelanto (firman Contrato de Factoring con Recurso y un Pagaré)."
			},
			{
			id: "3",
			question: "¿Cuáles son las garantías para los inversionistas?",
			answers:
				'<p>RedCapital cuenta son un Mandato Especial para realizar las gestiones de cobranza y ejecutar en nombre y representación del inversionista las acciones necesarias para el cobro del monto financiado a la Pyme. Todas las operaciones de factoring cuentan con las siguientes garantías:</p><ul><li>Factura negociable cedida a los inversionistas y con conformidad expresa del obligado al pago. Las facturas negociables son títulos valores con mérito ejecutivo inscritas en el Registro Centralizado de Facturas Negociables gestionado por CAVALI. </li><li>Pagaré suscrito por la empresa que recibe el adelanto de dinero.</li>'
			},
			{
			id: "4",
			question: "¿Cuáles son los riesgos?",
			answers:
				"<p>Al invertir en créditos -aunque estén garantizados-, siempre hay riesgos mínimos:</p><ul><li><strong>Riesgo de disconformidad por parte de la empresa obligada al pago:</strong>  La ley otorga 8 días útiles para que la empresa obligada al pago manifieste su disconformidad respecto de cualquier información consignada en la factura negociable (por ejemplo, el monto neto pendiente de pago o la fecha de vencimiento). Para mitigar ese riesgo, previo al desembolso de dinero, en RedCapital verificamos con el obligado al pago (vía correo electrónico) el monto neto y la fecha de pago consignado en la factura. Con esos datos, procedemos a anotar en cuenta la factura en el Registro Centralizado de Facturas Negociables a cargo de CAVALI, para obtener la conformidad expresa del obligado al pago. En caso de disconformidad por parte del obligado al pago, la solicitud de financiamiento presentada por la Pyme es denegada por RedCapital.</li><li><strong>Riesgo que la empresa pagadora haga descuentos a la factura:</strong> Este riesgo también se acota verificando previamente con el pagador que no realizará descuentos.</li><li><strong> Riesgo que la empresa pagadora quiebre:</strong> Por ello, es fundamental ver cuál es el pagador de la factura. RedCapital siempre informa al inversionista quien es el pagador, publicando su RUC. Además, en RedCapital sólo trabajamos con Buenos Pagadores según nuestro predictor de riesgo, es decir, empresas grandes o empresas que han tenido históricamente muy buen comportamiento de pago. Por otra parte, en caso que el pagador quiebre, también tenemos un pagaré de la empresa que nos cedió la factura.</li>"
			},
			{
			id: "5",
			question: "¿RedCapital garantiza las inversiones?",
			answers:
				"<p>No, RedCapital es una plataforma, donde los usuarios (inversionistas)  deciden en qué invertir.<br>Nuestra empresa entrega constantemente toda la información disponible a nuestros inversionistas para que puedan tomar las mejores decisiones, de manera informada y responsable.<br>En este contexto, la compañía no se hace responsable de las pérdidas que puedan generar estas inversiones. Por otra parte, los resultados pasados no garantizan rentabilidades futuras.</p><p>Garantías y riesgos de Facturas</p><p>Garantía: Factura cedida por el proveedor y pagaré firmado por este.<br>Plazo: Entre 30 y 120 días.</p><p>Respaldo de inversión:</p><p>El solicitante nos cede una factura que pagará un deudor, el cual es la empresa que recibió los productos o servicio. Este deudor se publica en la plataforma de modo que el inversionista sepa quién le pagará. Dado que la factura es cedida (título valor) pagará a los inversionistas en vez de la Pyme. RedCapital sólo trabaja con buenos pagadores.</p><p>RedCapital se encargá de hacer el cobro, para depositar a los inversionistas. En caso de problemas, por contrato y pagaré será la empresa que solicitó el crédito la que responda (factoring con recurso).</p><p>Riesgos:<br>8 días para que la factura posea el «mérito ejecutivo». Se acota, principalmente verificándola (llamando al pagador de la factura) para validar que será pagada y en qué condiciones. Quiebra del pagador. Trabajamos con buenos deudores y con recurso contra la empresa que cedió la factura. Tú eliges en qué pagador invertir. A mayor riesgo, mayor es la tasa. La mora es parte del negocio (en promedio de 15 días). En estos casos, generalmente la rentabilidad del inversionista aumenta por la tasa que aplicamos.</p>"
			},
			/*{
			id: "6",
			question: "Garantías y riesgos de Cheques",
			answers:
				"<p> Garantía : Cheques de varios clientes.<br>Plazo : Menor a 4 meses (120 días)<br><br><br>Respaldo de inversión<br>El solicitante entrega y endosa cheques de clientes que recibieron sus productos o servicios. Estos son cheques de varios clientes, por lo que se diversifica. El solicitante se publica en la plataforma de modo que el inversionista sepa quién le pagará en caso de cualquier problema. En caso que algún cheque no se pueda cobrar, tenemos contrato y pagaré con la empresa que solicitó el crédito, por lo que esta responde (cheques con responsabilidad). RedCapital se encarga de hacer el cobro, para depositar a los inversionistas. <br><br>Riesgos<br>El riesgo es principalmente que la empresa solicitante no tenga capacidad de pagar en caso que los cheques no se puedan cobrar. Acotamos los riesgos validando constantemente las ventas y balances de dicha empresa. En caso de mora, generalmente la rentabilidad del inversionista aumenta por la tasa que aplicamos (A lo sumo la tasa máxima convencional). <br><br></p>"
			},
			{
			id: "7",
			question: "Garantías y riesgos de Confirming",
			answers:
				"<p> Garantía : Factura a proveedor.<br>Plazo : Menor a 4 meses (120 días)<br><br><br>Respaldo de inversión<br>Varias empresas ceden sus facturas a los inversionistas a través de RedCapital. El deudor, que recibió los productos o servicio se publica en la plataforma de modo que el inversionista sepa quién le pagará. Dado que la factura es cedida (título ejecutivo) pagará a los inversionistas en vez de la Pyme, bajo el amparo de la Ley 19.983. RedCapital sólo trabaja con buenos pagadores.<br><br>RedCapital se encarga de hacer el cobro, para depositar a los inversionistas. En caso de problemas, por contrato y pagaré será la empresa que solicitó el crédito la que responda (factoring con responsabilidad). <br><br>Riesgos<br>Principalmente, que la empresa que pagará las facturas tenga problemas de liquidez, por lo que este cliente es analizado por nuestro equipo experto.Queda descartando riesgo operativo de los 8 días de rechazo de la factura al estar confirmado con el pagador. La mora es parte del negocio (en promedio de 15 días). En estos casos, generalmente la rentabilidad del inversionista aumenta por la tasa que aplicamos (A lo sumo la tasa máxima convencional). <br><br></p>"
			},
			{
			id: "8",
			question: "Garantías y riesgos de Facturas",
			answers:
				"<p> Garantía : Factura a proveedor.<br>Plazo : Menor a 4 meses (120 días)<br><br><br>Respaldo de inversión<br>El solicitante nos cede una factura que pagará un deudor, el cual es la empresa que recibió los productos o servicio. Este deudor se publica en la plataforma de modo que el inversionista sepa quién le pagará. Dado que la factura es cedida (título ejecutivo) pagará a los inversionistas en vez de la Pyme, bajo el amparo de la Ley 19.983. RedCapital sólo trabaja con buenos pagadores.<br><br>RedCapital se encargá de hacer el cobro, para depositar a los inversionistas. En caso de problemas, por contrato y pagaré será la empresa que solicitó el crédito la que responda (factoring con responsabilidad). <br><br>Riesgos<br>8 días para que la factura posea el «título ejecutivo». Se acota, principalmente verificándola (llamando al pagador de la factura) para validar que será pagada y en qué condiciones. Quiebra del pagador. Trabajamos con buenos deudores y con responsabilidad de la empresa que cedió la factura. Tú eliges en qué pagador invertir. A mayor riesgo mayor es la tasa.La mora es parte del negocio (en promedio de 15 días). En estos casos, generalmente la rentabilidad del inversionista aumenta por la tasa que aplicamos (A lo sumo la tasa máxima convencional). <br><br></p>"
			},
			{
			id: "9",
			question: "Garantías y riesgos de Créditos SGR",
			answers:
				' <p>Créditos con Aval SGR (Fondos Corfo)</p><p>Garantía : Pagaré de la Pyme Certificado de Fianza SGR (Fondos Corfo).</p><p>Plazo : Generalmente 3 a 12 Meses (máximo 5 años)</p><p>Respaldo de inversión:</p><p> <span class="font-weight-bold">Pagaré Notarial de la Pyme:</span> Este pagaré se firma ante notaría a nombre de cada Inversionista y detalla las condiciones del crédito. </p><p> <span class="font-weight-bold">Certificado de Fianza SGR (Fondos Corfo):</span> Este certificado queda a nombre de cada Inversionista. Permite cobrar el dinero invertido en caso de no pago de la Pyme </p><p> <span class="font-weight-bold">Las SGR:</span> son empresas que administran fondos de Corfo que permiten garantizar hasta un 100% del capital invertido. Son regidas por Ley 20.179, supervisadas por Corfo y la Superintendencia de Bancos e Instituciones Financieras (SBIF). </p><p> <span class="font-weight-bold">Riesgos:</span> En caso del no pago del crédito por parte de la Pyme. Se ejecutan los certificado de fianza y se acuerda con las SGR la forma de pago. Los inversionistas recibirán el monto invertido y los intereses. Trabajamos con las mejores SGR para minimizar los riesgos en caso de quiebra, además revisamos estados ﬁnancieros de las SGR, las clasiﬁcaciones de riesgo y nos reunimos periódicamente con CORFO para tener su feedback. </p>'
			}*/
		],
		isLoad: true /* TODO: al implementar la api utilizar esta variable para visualizar el showloading */
		};
	},
	computed: {
		filteredList() {
			return this.$data.warranty.filter((warranty: any) => {
				return Normalizer.string(warranty.question).includes(
					Normalizer.string(this.$data.search)
				);
			});
		}
	},
	watch: {
		search(val: any) {
			if (val == null) {
				this.$data.search = "";
			}
		}
	},
	components:{
		CollapseQuestions,
		ShowLoading
	}
})
export default class Warranty extends Vue {}
